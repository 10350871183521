<template>
  <div class="contract-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <app-overview title="// 合同详情 //" :list="actualList"></app-overview>
  </div>
</template>
<script>
  import axios from "axios";
  import Vue from "vue";
  export default {
    data() {
      let _this = this;
      return {
        title: "合同详情",
        actualList: [{
            label: "合同编号",
            key: "id",
            value: ""
          },
          {
            label: "合同类型",
            key: "contractType",
            value: ""
          },
          {
            label: "签约人",
            key: "tenantName",
            value: ""
          },
          {
            label: "签约时间",
            key: "createDate",
            value: ""
          },
          {
            label: "签约房间",
            key: "cityName",
            value: ""
          },
          {
            label: "签约人电话",
            key: "tenantPhone",
            value: ""
          },
          {
            label: "租期",
            key: "rentDate",
            value: ""
          },
          {
            label: "租金（元）",
            key: "rentalFee",
            value: ""
          },
          {
            label: "押金（元）",
            key: "deposit",
            value: ""
          },
          {
            label: "物业费（元）",
            key: "propertyFee",
            value: ""
          },
          {
            label: "房东",
            key: "landlordName",
            value: ""
          },
          {
            label: "付款方式",
            key: "payType",
            value: ""
          },
          {
            label: "房东电话",
            key: "landlordPhone",
            value: ""
          },
          {
            label: "合同状态",
            key: "state",
            value: ""
          },
          // {
          //   label: "合同正文",
          //   key: "",
          //   value: "查看",
          //   opt: {
          //     value: "查看",
          //     on(row) {
          //       console.log(_this.contentList);
          //       _this.$router.push({
          //         path: "/main/tenant/hetong/show/0",
          //         query: {
          //           id: _this.contentList.id
          //         }
          //       });
          //     }
          //   }
          // }
        ],
        tit: "",
        opt: {
          title: "账单明细(" + _this.cache.get("statementDetailTitle", "") + ")",
          isShowBack: true,
          search: [{
            key: "reconState",
            label: "对账状态",
            type: "select",
            opt: {
              list: [{
                  label: "正常",
                  value: 1
                },
                {
                  label: "异常",
                  value: 2
                },
                {
                  label: "已冲正",
                  value: 3
                }
              ]
            }
          }]
        },
        contentList: {}
      };
    },
    activated() {
      this.actualList = [{
          label: "合同编号",
          key: "id",
          value: ""
        },
        {
          label: "合同类型",
          key: "contractType",
          value: ""
        },
        {
          label: "签约人",
          key: "tenantName",
          value: ""
        },
        {
          label: "签约时间",
          key: "createDate",
          value: ""
        },
        {
          label: "签约房间",
          key: "cityName",
          value: ""
        },
        {
          label: "签约人电话",
          key: "tenantPhone",
          value: ""
        },
        {
          label: "租期",
          key: "rentDate",
          value: ""
        },
        {
          label: "租金（元）",
          key: "rentalFee",
          value: ""
        },
        {
          label: "押金（元）",
          key: "deposit",
          value: ""
        },
        {
          label: "物业费（元）",
          key: "propertyFee",
          value: ""
        },
        {
          label: "房东",
          key: "landlordName",
          value: ""
        },
        {
          label: "付款方式",
          key: "payType",
          value: ""
        },
        {
          label: "房东电话",
          key: "landlordPhone",
          value: ""
        },
        {
          label: "合同状态",
          key: "state",
          value: ""
        },
        // {
        //   label: "合同正文",
        //   key: "",
        //   value: "查看",
        //   opt: {
        //     value: "查看",
        //     on(row) {
        //       console.log(_this.contentList);
        //       _this.$router.push({
        //         path: "/main/tenant/hetong/show/0",
        //         query: {
        //           id: _this.contentList.id
        //         }
        //       });
        //     }
        //   }
        // }
      ];
      this.actualList.forEach((item, index) => {
        //重置
        if (item.key == "cancelTime") {
          this.actualList.splice(index, 3);
        }
      });
      this.onGet();
    },
    methods: {
      onGet(opt) {
        let _this = this;
        let dto = {
          id: this.$route.query.id
        };
        this.post("/tenant-service/leasecontract/queryTenantContractById", dto, {
          isUseResponse: true
        }).then(res => {
          var data = res.data.data;
          this.contentList = res.data.data;
          var keys = [];
          for (var key in data) {
            keys.push(key);
          }
          if (this.cache.getLS("userInfo")["roleType"] != "8") {
            data.tenantPhone = data.tenantPhone ?
              data.tenantPhone.substring(0, 3) +
              "****" +
              data.tenantPhone.substring(7, data.tenantPhone.length) :
              "";
            data.landlordPhone = data.landlordPhone ?
              data.landlordPhone.substring(0, 3) +
              "****" +
              data.landlordPhone.substring(7, data.landlordPhone.length) :
              "";
          }
          if (data["contractType"] == "2" && data["downloadUrl"]) {
            this.actualList.push({
              label: "查看合同",
              key: "",
              value: "查看",
              opt: {
                value: "查看",
                on(row) {
                  var tempwindow = window.open('_blank');
                  tempwindow.location = data["downloadUrl"];
                }
              }
            });
            this.actualList.push({
              label: "下载",
              key: "",
              value: "下载合同",
              opt: {
                value: "下载合同",
                on(row) {
                  axios({
                      method: "get",
                      url: Vue.config.BASE_URL + "/tenant-service/leasecontract/downloadContractFile/" + data[
                        "id"],
                      responseType: "blob"
                    })
                    .then(response => {
                      var elink = document.createElement("a");
                      elink.download = "合同.pdf";
                      elink.style.display = "none";
                      var blob = new Blob([response.data]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                    })
                    .catch(error => {
                      window.open(data["downloadUrl"]);
                    });
                }
              }
            });

          } else {
            // this.actualList.pop();
          }
          let stateFlag = "";
          this.actualList.forEach(item => {
            const _roomDetails =
              data["cityName"] +
              data["apartmentName"] +
              data["buildNo"] + data["roomNo"];
            if (item.key == "cityName") {
              data[item.key] = _roomDetails;
            }
            if (item.key == "state") {
              // data["state"] = ["待支付","签约成功","签约失败","合同到期","已解约"][data["state"] ];
              if (data["state"] == "0") {
                data["state"] = "待生效";
              }else if (data["state"] == "1") {
                data["state"] = "履约中";
              }else if (data["state"] == "2") {
                data["state"] = "签约失败";
              } else if (data["state"] == "3") {
                data["state"] = "已过期";
              } else if (data["state"] == "4") {
                data["state"] = "已解约";
              } else if (data["state"] == "5") {
                data["state"] = "强制解约";
                stateFlag = "5";
              }
            }
            if (item.key == "cancelTime") {
              //避免多次添加
              stateFlag = "";
            }
            if (item.key == "contractType") {
              //合同
              if (data["contractType"] == 1) {
                data["contractType"] = "线下纸质合同";
              } else if (data["contractType"] == 2) {
                data["contractType"] = "CA电子合同";
              }
            }
            if (item.key == "rentDate") {
              //租期
              data["rentDate"] = data["startDate"] + '~' + data["endDate"];
            }
            item.value = data[item.key];
          });
          if (stateFlag == "5") {
            this.actualList.push({
              label: "强制解约时间",
              key: "cancelTime",
              value: data["cancelTime"]
            }, {
              label: "解约类型",
              key: "rescindType",
              value: data["rescindType"]
            }, {
              label: "解约说明",
              key: "remark",
              value: data["remark"]
            });
          }
        });
      }
    }
  };
</script>