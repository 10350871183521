var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contract-detail" },
    [
      _c("app-header", { attrs: { title: _vm.title, isShowBack: true } }),
      _c("app-overview", {
        attrs: { title: "// 合同详情 //", list: _vm.actualList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }